import { Box, Button, Flex, Icon, Stack, Text } from '@chakra-ui/react';
import { ShuffleIcon } from '@clerk-ui/assets/icons';
import React, { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { buildPreviewAvatarURL } from '@utils';

type AvatarPreviewProps = {
  type: 'user' | 'org';
};
export const AvatarPreview = ({ type }: AvatarPreviewProps): JSX.Element => {
  const { watch } = useFormContext();
  const values = watch();
  const [seed, setSeed] = useState('seed');
  const [src, setSrc] = useState(null);

  const valuesStringified = JSON.stringify(values[type]);
  useEffect(() => {
    const url = buildPreviewAvatarURL({
      size: 48,
      seed: seed,
      initials: type === 'user' ? 'AD' : 'A',
      isSquare: true,
      bgType: values[type].bgType,
      bgColor: values[type].bgColor,
      fgType: values[type].fgType,
      fgColor: values[type].fgColor,
      type,
    });
    setSrc(url.toString());
  }, [valuesStringified, seed]);

  return (
    <Stack spacing={4} mt={10}>
      <Text textStyle='md-medium'>Preview</Text>
      <Flex>
        <Box
          borderRadius={type === 'user' ? '50%' : '8px'}
          overflow='hidden'
          width='48px'
          height='48px'
        >
          <img alt={'avatar preview'} src={src}></img>
        </Box>
        <Button
          variant='link'
          ml={3}
          onClick={() => setSeed(Date.now().toString())}
        >
          <Icon as={ShuffleIcon} boxSize={5} />
        </Button>
      </Flex>
    </Stack>
  );
};
