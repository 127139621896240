import React from 'react';
import { Box, Center, Flex, Image, Spinner, Text } from '@chakra-ui/react';

export const frameworkActiveStyles = {
  background: 'gray.50',
  borderColor: 'gray.50',
};

type FrameworkProps = {
  logo: string;
  title: string;
  isLoading?: boolean;
};

export function Framework({
  logo,
  title,
  isLoading,
}: FrameworkProps): JSX.Element {
  return (
    <Flex
      direction='column'
      align='center'
      justify='center'
      px='6'
      py='4'
      _hover={frameworkActiveStyles}
      _active={frameworkActiveStyles}
      rounded='lg'
      border='1px solid transparent'
    >
      <Box pos='relative' mb={3}>
        <Image src={logo} alt={title} boxSize={12} />

        {isLoading && (
          <Center boxSize='full' pos='absolute' top={0} left={0}>
            <Box
              boxSize='full'
              bg='white'
              opacity='0.5'
              pos='absolute'
              rounded='lg'
            />

            <Spinner size='md' />
          </Center>
        )}
      </Box>

      <Box>
        <Text
          textStyle='md-medium'
          color='gray.500'
          _groupHover={{ color: 'black' }}
          _groupActive={{ color: 'black' }}
        >
          {title}
        </Text>
      </Box>
    </Flex>
  );
}
