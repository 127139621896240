export type Starter = {
  title: string;
  logo: string;
  guide_url: string;
};

export const STARTERS: Starter[] = [
  {
    title: 'Next.js',
    logo: '/assets/logos/nextjs.svg',
    guide_url: 'https://clerk.com/docs/quickstarts/get-started-with-nextjs',
  },
  {
    title: 'Expo',
    logo: '/assets/logos/expo.svg',
    guide_url: 'https://clerk.com/docs/reference/clerk-expo',
  },
  {
    title: 'Remix',
    logo: '/assets/logos/remix.svg',
    guide_url: 'https://clerk.com/docs/quickstarts/get-started-with-remix',
  },
  {
    title: 'Gatsby',
    logo: '/assets/logos/gatsby.svg',
    guide_url: 'https://clerk.com/docs/quickstarts/get-started-with-gatsby',
  },

  {
    title: 'RedwoodJS',
    logo: '/assets/logos/redwoodjs.svg',
    guide_url: 'https://clerk.com/docs/quickstarts/get-started-with-redwoodjs',
  },
  {
    title: 'React',
    logo: '/assets/logos/react.svg',
    guide_url:
      'https://clerk.com/docs/quickstarts/get-started-with-create-react-app',
  },
  {
    title: 'JavaScript',
    logo: '/assets/logos/javascript.svg',
    guide_url: 'https://clerk.com/docs/reference/clerkjs/installation',
  },
];
