import React from 'react';
import { PageLoader } from '@components/common';
import {
  useDashboardCRUD,
  useDashboardSWR,
  useLocation,
  useToast,
} from '@hooks';
import { Settings, AttributeSettings } from '@types';
import { UserObjectModelForm } from './UserObjectModelForm';
import { Stack } from '@chakra-ui/react';

export function UserObjectModel(): JSX.Element {
  const { instanceId } = useLocation();
  const { data: settings, mutate } = useDashboardSWR<Settings>(
    `/v1/instances/${instanceId}/user_settings`,
  );
  const { update } = useDashboardCRUD();
  const { showSuccessToast } = useToast();

  const onSubmit = async (settings: Partial<AttributeSettings>) => {
    try {
      const newUserSettings = await update(
        `/v1/instances/${instanceId}/user_settings`,
        settings,
      );
      await mutate(newUserSettings as Settings, false);
      showSuccessToast('Standard authentication settings saved.');
    } catch (err) {
      return;
    }
  };

  if (!settings) {
    return <PageLoader />;
  }

  return (
    <Stack spacing='8'>
      <UserObjectModelForm settings={settings} onSubmit={onSubmit} />
    </Stack>
  );
}
