import React from 'react';
import { Stack, useDisclosure, VStack } from '@chakra-ui/react';
import { CardDualPanel, StickyBottomCTA } from '@components/common';
import { FormProvider, useForm } from 'react-hook-form';
import {
  SECTION_ATTRIBUTES,
  USED_FOR_FIRST_FACTOR_LABEL,
  VERIFICATION_METHOD_LABELS,
  VERIFY_AT_SIGN_UP_LABEL,
} from './constants';
import {
  RegisteredSwitchInfoField as SwitchInfoField,
  SwitchInfoFieldWithBillingModal,
} from '@components/common/Switch';
import { useSupportedFeature } from '@hooks';
import {
  GenericBadge as Badge,
  OptionalBadge,
  RequiredBadge,
} from '@components/common/Badges';
import { AttributeSettings, OauthProviders, UserSettings } from '@types';
import {
  areContactInfoOff,
  getDirtyValues,
  hasAuthFactorEnabled,
  hasSocialAuthenticatable,
} from '@utils';
import { AvailableAttributes, getAttributeDetailsModal } from './modals';
import { AuthenticationFactorsForm } from './AuthenticationFactorsForm';
import { useDependentFields } from './useDependentFields';

export const FORM_ID = 'user_object_model_form';

type UserObjectModelFormProps = {
  settings: UserSettings;
  onSubmit: (attributes: Partial<AttributeSettings>) => Promise<void>;
};

export function UserObjectModelForm({
  settings,
  onSubmit,
}: UserObjectModelFormProps): JSX.Element {
  const [activeAttributeName, setActiveAttributeName] =
    React.useState<AvailableAttributes>();
  const [setupWarning, setSetupWarning] = React.useState<string>(null);
  const { isSupported, isPremium, isUnsupportedAndEnabled } =
    useSupportedFeature();

  const { isOpen, onOpen, onClose } = useDisclosure();
  const formMethods = useForm<AttributeSettings & OauthProviders>({
    mode: 'onChange',
    defaultValues: settings,
  });

  const {
    formState: { isSubmitting, dirtyFields, isDirty },
    getValues,
    handleSubmit,
    reset,
    watch,
  } = formMethods;

  useDependentFields(formMethods);

  const isEmailAddressEnabled = watch('attributes.email_address.enabled');
  const isPhoneNumberEnabled = watch('attributes.phone_number.enabled');
  const isPasswordRequired = watch('attributes.password.required');
  const emailAddressFirstFactors = watch(
    'attributes.email_address.first_factors',
  );
  const phoneNumberFirstFactors = watch(
    'attributes.phone_number.first_factors',
  );

  React.useEffect(() => {
    if (areContactInfoOff(getValues())) {
      setSetupWarning("You haven't enabled any contact information.");
    } else if (
      !hasAuthFactorEnabled(getValues()) &&
      !hasSocialAuthenticatable(getValues())
    ) {
      setSetupWarning("You haven't enabled any authentication factors.");
    } else if (
      !isPhoneNumberEnabled &&
      settings.attributes.phone_number.used_for_second_factor
    ) {
      setSetupWarning(
        'Disabling Phone number contact information will disable Multi-factor authentication via phone code',
      );
    } else {
      setSetupWarning(null);
    }
  }, [
    isEmailAddressEnabled,
    isPhoneNumberEnabled,
    isPasswordRequired,
    emailAddressFirstFactors,
    phoneNumberFirstFactors,
  ]);

  React.useEffect(() => {
    reset(settings);
  }, [settings, reset]);

  const openModal = name => {
    setActiveAttributeName(name);
    onOpen();
  };

  const closeModal = () => {
    setActiveAttributeName(null);
    onClose();
  };

  const saveMainForm = async (formData: AttributeSettings & OauthProviders) => {
    const dirtyValues = getDirtyValues(formData, dirtyFields, 'attributes') || {
      attributes: {},
    };

    await onSubmit(dirtyValues);
  };

  const getBadges = (attributeName: string): JSX.Element[] => {
    const {
      enabled,
      required,
      verifications,
      used_for_first_factor,
      verify_at_sign_up,
    } = getValues().attributes[attributeName] || {};

    const badges = [];
    if (settings.sign_up.progressive) {
      if (enabled) {
        badges.push(required ? <RequiredBadge /> : <OptionalBadge />);
      }
    } else {
      if (enabled && attributeName === 'first_name') {
        badges.push(required ? <RequiredBadge /> : <OptionalBadge />);
      }
      if (enabled && attributeName === 'username') {
        badges.push(required ? <RequiredBadge /> : <OptionalBadge />);
      }
    }

    used_for_first_factor &&
      badges.push(<Badge label={USED_FOR_FIRST_FACTOR_LABEL} />);

    verify_at_sign_up && badges.push(<Badge label={VERIFY_AT_SIGN_UP_LABEL} />);

    verifications &&
      verifications.forEach(verification => {
        badges.push(<Badge label={VERIFICATION_METHOD_LABELS[verification]} />);
      });

    return badges;
  };

  const resetMainForm = () => reset();

  const ActiveModal = getAttributeDetailsModal(activeAttributeName);

  const handleFeature = (featureName: string) => {
    return (
      isSupported(featureName) ||
      isUnsupportedAndEnabled({
        feature: featureName,
        currentValue: getValues('attributes.phone_number.enabled'),
      })
    );
  };

  return (
    <FormProvider {...formMethods}>
      <form id={FORM_ID} onSubmit={handleSubmit(saveMainForm)}>
        <Stack spacing='8'>
          <CardDualPanel
            title='Contact information'
            subtitle='Specify whether your users should have email addresses or phone numbers'
          >
            <VStack align='stretch'>
              {SECTION_ATTRIBUTES[0].content.map(
                ({
                  title,
                  key,
                  description,
                  hasExtraSettings,
                  featureName,
                }) => {
                  const onCogClick = hasExtraSettings
                    ? () => openModal(key)
                    : null;

                  return (
                    <SwitchInfoFieldWithBillingModal
                      key={key}
                      name={`attributes.${key}.enabled`}
                      title={title}
                      description={description}
                      badges={getBadges(key)}
                      onCogClick={onCogClick}
                      minBadgeHeight='18px'
                      featureName={featureName}
                      isPremiumFeature={featureName && isPremium(featureName)}
                      isFeatureSupported={
                        featureName ? handleFeature(featureName) : true
                      }
                    />
                  );
                },
              )}
            </VStack>
          </CardDualPanel>

          <CardDualPanel
            title='Username'
            subtitle='Specify whether your users have a unique username'
          >
            <VStack align='stretch'>
              {SECTION_ATTRIBUTES[1].content.map(
                ({ title, key, description, hasExtraSettings }) => {
                  const onCogClick = hasExtraSettings
                    ? () => openModal(key)
                    : null;

                  return (
                    <SwitchInfoField
                      key={key}
                      name={`attributes.${key}.enabled`}
                      title={title}
                      description={description}
                      badges={getBadges(key)}
                      onCogClick={onCogClick}
                      minBadgeHeight='18px'
                    />
                  );
                },
              )}
            </VStack>
          </CardDualPanel>

          <AuthenticationFactorsForm />

          <CardDualPanel
            title='Personal information'
            subtitle='Specify whether your users have extra personal information'
          >
            <VStack align='stretch'>
              {SECTION_ATTRIBUTES[2].content.map(
                ({ title, key, description, hasExtraSettings }) => {
                  const onCogClick = hasExtraSettings
                    ? () => openModal(key)
                    : null;

                  return (
                    <SwitchInfoField
                      key={key}
                      name={`attributes.${key}.enabled`}
                      title={title}
                      description={description}
                      badges={getBadges(key)}
                      onCogClick={onCogClick}
                      minBadgeHeight='18px'
                    />
                  );
                },
              )}
            </VStack>
          </CardDualPanel>
        </Stack>
        {ActiveModal && (
          <ActiveModal
            isOpen={isOpen}
            onClose={closeModal}
            isProgressiveSignUp={settings.sign_up.progressive}
          />
        )}
        <StickyBottomCTA
          isVisible={isDirty && !isOpen}
          onReset={resetMainForm}
          isSubmitting={isSubmitting}
          formId={FORM_ID}
          warningMsg={setupWarning}
        />
      </form>
    </FormProvider>
  );
}
