import React from 'react';
import { VStack } from '@chakra-ui/react';
import { CardDualPanel } from '@components/common';
import { AUTHENTICATION_FACTORS_ATTRIBUTES_INFO } from './constants';
import {
  SwitchInfoField,
  SwitchInfoFieldWithBillingModal,
} from '@components/common/Switch';
import { useSupportedFeature } from '@hooks';
import { Controller, useFormContext } from 'react-hook-form';
import { usePaymentRequired } from '@context/PaymentRequiredContext';
import { SUPPORTED_FEATURES } from '@constants';

const handleCheckboxChange = (isChecked, onChange, value = [], key) =>
  onChange(isChecked ? [...value, key] : value.filter(v => v !== key));

const getFactorInfo = (field, isAttributeOff) => {
  if (isAttributeOff) {
    return AUTHENTICATION_FACTORS_ATTRIBUTES_INFO[field];
  }
  return null;
};

export function AuthenticationFactorsForm(): JSX.Element {
  const { getValues, control } = useFormContext();
  const { showModal } = usePaymentRequired();
  const {
    isSupported,
    isPremium,
    hasNotSeenInfoBillingModal,
    isUnsupportedAndEnabled,
  } = useSupportedFeature();

  const isEmailAddressOff = !getValues(
    'attributes.email_address.used_for_first_factor',
  );
  const isPhoneNumberOff = !getValues(
    'attributes.phone_number.used_for_first_factor',
  );
  const isUsernameOff = !getValues('attributes.username.used_for_first_factor');

  const shouldDisablePassword =
    isEmailAddressOff && isPhoneNumberOff && isUsernameOff;

  const handleEmailCode = (onChange, value, isChecked) => {
    const feature = SUPPORTED_FEATURES.email_code;

    if (
      !isSupported(feature) &&
      !isUnsupportedAndEnabled({
        feature,
        currentValue: value?.includes('email_code'),
      })
    ) {
      return showModal({ features: [feature] });
    }

    if (hasNotSeenInfoBillingModal(feature) && !value?.includes('email_code')) {
      showModal({
        callbackAfterClose: () =>
          handleCheckboxChange(isChecked, onChange, value, 'email_code'),
      });
    } else {
      return handleCheckboxChange(isChecked, onChange, value, 'email_code');
    }
  };

  const isPassportSupported =
    isSupported(SUPPORTED_FEATURES.password) ||
    isUnsupportedAndEnabled({
      feature: SUPPORTED_FEATURES.password,
      currentValue: getValues('attributes.password.required'),
    });

  return (
    <CardDualPanel
      title='Authentication factors'
      subtitle='Select the authentication methods to present when a user signs in'
      docLink={{
        subject: 'authentication factors',
        url: 'https://clerk.com/docs/authentication/set-up-your-application',
      }}
    >
      <VStack align='stretch'>
        <SwitchInfoFieldWithBillingModal
          name='attributes.password.required'
          title='Password'
          description='Users can sign in with a password. Passwords are required during sign up unless the user signs up with a Social Connection or a Web3 wallet.'
          info={getFactorInfo('password', shouldDisablePassword)}
          isDisabled={shouldDisablePassword}
          isFeatureSupported={isPassportSupported}
          featureName='password'
        />

        <Controller
          control={control}
          name='attributes.email_address.first_factors'
          render={({ field: { onChange, value } }) => (
            <>
              <SwitchInfoField
                name='email_link'
                title='Email verification link'
                description='Users can sign in with an email verification link'
                info={getFactorInfo('email_link', isEmailAddressOff)}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  handleCheckboxChange(
                    e.target.checked,
                    onChange,
                    value,
                    'email_link',
                  )
                }
                isChecked={value?.includes('email_link')}
                isDisabled={isEmailAddressOff}
              />
              <SwitchInfoField
                name='email_code'
                title='Email verification code'
                description='Users can sign in with an email verification code'
                info={getFactorInfo('email_code', isEmailAddressOff)}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  handleEmailCode(onChange, value, e.target.checked)
                }
                isPremiumFeature={isPremium(SUPPORTED_FEATURES.email_code)}
                isChecked={value?.includes('email_code')}
                isDisabled={isEmailAddressOff}
              />
            </>
          )}
        />

        <Controller
          control={control}
          name='attributes.phone_number.first_factors'
          render={({ field: { onChange, value } }) => (
            <SwitchInfoField
              name='phone_code'
              title='SMS verification code'
              description='Users can sign in with an SMS verification code'
              info={getFactorInfo('phone_code', isPhoneNumberOff)}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                handleCheckboxChange(
                  e.target.checked,
                  onChange,
                  value,
                  'phone_code',
                )
              }
              isChecked={value?.includes('phone_code')}
              isDisabled={isPhoneNumberOff}
            />
          )}
        />
      </VStack>
    </CardDualPanel>
  );
}
