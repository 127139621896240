import { FormControl, Radio, RadioGroup, Stack, Text } from '@chakra-ui/react';
import { ColorInput, VerticalInputBox } from '@components/common';
import { Controller, useController, useFormContext } from 'react-hook-form';
import debounce from 'lodash/debounce';
import React from 'react';

type AvatarForegroundRadioGroupSubLabels = {
  initials: string;
  silhouette: string;
};

type AvatarForegroundRadioGroupProps = {
  type: 'org' | 'user';
  subLabels: AvatarForegroundRadioGroupSubLabels;
};

export const AvatarForegroundRadioGroup = ({
  type,
  subLabels,
}: AvatarForegroundRadioGroupProps): JSX.Element => {
  const { watch, formState, control, resetField } = useFormContext();
  const { field } = useController({ name: `${type}.fgType`, control });
  const { errors } = formState;
  const values = watch();
  return (
    <RadioGroup
      onChange={nextValue => field.onChange(nextValue)}
      value={field.value}
    >
      <Stack direction='column' spacing={4}>
        <Radio value='initials' alignItems={'flex-start'}>
          <VerticalInputBox label='Initials' subLabel={subLabels.initials}>
            {values[type].fgType === 'initials' && (
              <FormControl
                isInvalid={!!errors?.[type]?.fgColor}
                width={'195px'}
              >
                <Controller
                  control={control}
                  name={`${type}.fgColor`}
                  render={({ field: { onChange, value, name } }) => {
                    const handleChange = debounce(e => {
                      onChange(e.slice(1));
                    }, 300);
                    return (
                      <ColorInput
                        name={name}
                        value={`#${value}`}
                        onChange={handleChange}
                      />
                    );
                  }}
                />
              </FormControl>
            )}
          </VerticalInputBox>
        </Radio>
        <Radio value='silhouette' alignItems={'flex-start'}>
          <VerticalInputBox label='Silhouette' subLabel={subLabels.silhouette}>
            {values[type].fgType === 'silhouette' && (
              <FormControl
                isInvalid={!!errors?.[type]?.fgColor}
                width={'195px'}
              >
                <Controller
                  control={control}
                  name={`${type}.fgColor`}
                  render={({ field: { onChange, value, name } }) => {
                    const handleChange = debounce(e => {
                      onChange(e.slice(1));
                    }, 300);
                    return (
                      <ColorInput
                        name={name}
                        value={`#${value}`}
                        onChange={handleChange}
                      />
                    );
                  }}
                />
              </FormControl>
            )}
          </VerticalInputBox>
        </Radio>
        <Radio
          value='none'
          onChange={() => resetField(`${type}.fgColor`)}
          alignItems={'flex-start'}
        >
          <Text textStyle='md-medium'>None</Text>
        </Radio>
      </Stack>
    </RadioGroup>
  );
};
