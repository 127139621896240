import { Box, Center, Spinner, Text, useDisclosure } from '@chakra-ui/react';
import React from 'react';
import { useLocation, useToast } from '@hooks';
import { useDashboardCRUD, useDashboardSWR } from '@hooks/useDashboardAPI';
import { KeysTable } from './KeysTable';
import type { InstanceKey } from './types';
import { InstanceKeyObject } from './types';
import copy from 'clipboard-copy';
import { DeleteInstanceKeyModal } from './DeleteInstanceKeyModal';
import { CreateInstanceKeyModal } from './CreateInstanceKeyModal';
import { CardSinglePanel } from '@components/common';
import { HIDDEN_SECRET_VALUE } from './TableRow';
import { HTTPError } from '@utils';

enum ModalType {
  DeleteInstanceKey = 'deleteInstanceKey',
  CreateInstanceKey = 'createInstanceKey',
}

export function BackendAPIKeys(): JSX.Element {
  const { instanceId } = useLocation();
  const { showErrorToast } = useToast();
  const [instanceKeys, setInstanceKeys] = React.useState<InstanceKey[]>();
  const {
    data: instanceKeysCensored,
    isValidating,
    mutate,
  } = useDashboardSWR<InstanceKey[]>(
    `/v1/instances/${instanceId}/instance_keys`,
  );
  const [activeModal, setActiveModal] = React.useState<ModalType>();
  const [activeInstanceKeyId, setActiveInstanceKeyId] =
    React.useState<InstanceKey>();
  const { isOpen, onClose, onOpen } = useDisclosure();
  const { del, read } = useDashboardCRUD<InstanceKey>();

  React.useEffect(() => {
    setInstanceKeys(instanceKeysCensored);
  }, [instanceKeysCensored]);

  const updateInstanceKey = (newInstanceKey: InstanceKey) => {
    const staleInstanceKeyIndex = instanceKeys.findIndex(
      ({ id }) => id === newInstanceKey.id,
    );
    const newInstanceKeys = [...instanceKeys];
    newInstanceKeys[staleInstanceKeyIndex] = newInstanceKey;
    setInstanceKeys(newInstanceKeys);
  };

  const handleDeleteInstanceKey = React.useCallback(async () => {
    try {
      await del(
        `/v1/instances/${instanceId}/instance_keys/${activeInstanceKeyId}`,
      );
      void mutate();
      onClose();
    } catch (err) {
      const errorMessage = (err as HTTPError)?.globalErrors[0];
      showErrorToast(
        errorMessage.message || 'Something went wrong, please try again later.',
      );
      onClose();
    }
  }, [activeInstanceKeyId]);

  const handleConfirmDelete = React.useCallback(async instanceKeyId => {
    setActiveModal(ModalType.DeleteInstanceKey);
    setActiveInstanceKeyId(instanceKeyId);
    onOpen();
  }, []);

  const handleShowKey = React.useCallback(
    async instanceKeyId => {
      try {
        const newInstanceKey = await read(
          `/v1/instances/${instanceId}/instance_keys/${instanceKeyId}`,
        );
        updateInstanceKey(newInstanceKey);
      } catch (error) {
        // read function will show an error notification
      }
    },
    [updateInstanceKey],
  );

  const handleHideKey = React.useCallback(
    (instanceKeyId: string) => {
      const instanceToUpdate = instanceKeys.find(
        instance => instance.id === instanceKeyId,
      );
      instanceToUpdate.secret = HIDDEN_SECRET_VALUE;
      updateInstanceKey(instanceToUpdate);
    },
    [updateInstanceKey, instanceKeys],
  );

  const handleGetToken = React.useCallback(async instanceKeyId => {
    const instanceKey = await read(
      `/v1/instances/${instanceId}/instance_keys/${instanceKeyId}`,
    );
    void copy(instanceKey.secret);
  }, []);

  const handleCreateInstanceKeyClose = React.useCallback(
    ({ revalidateKeys }) => {
      if (revalidateKeys) {
        void mutate();
      }
      onClose();
    },
    [mutate],
  );

  const onlyInstanceKeys =
    instanceKeys
      ?.filter(key => key.object === InstanceKeyObject.InstanceKey)
      ?.sort((i1, i2) => i2.created_at - i1.created_at) || [];

  const isDeleteModalOpen =
    isOpen && activeModal === ModalType.DeleteInstanceKey;
  const isCreateModalOpen =
    isOpen && activeModal === ModalType.CreateInstanceKey;

  return (
    <CardSinglePanel
      title='Legacy Backend API keys'
      subtitle={
        <>
          <Text as='span' textStyle='sm-normal' mt='2'>
            These are the secret keys to be used from your backend code.
          </Text>
        </>
      }
      bgColor='gray.50'
    >
      <Box>
        {isValidating ? (
          <Center height='200px'>
            <Spinner />
          </Center>
        ) : (
          <KeysTable
            instanceKeys={onlyInstanceKeys}
            onDelete={handleConfirmDelete}
            onShowKey={handleShowKey}
            onHideKey={handleHideKey}
            onCopyToken={handleGetToken}
          />
        )}
        <CreateInstanceKeyModal
          isOpen={isCreateModalOpen}
          onClose={handleCreateInstanceKeyClose}
        />
        <DeleteInstanceKeyModal
          isOpen={isDeleteModalOpen}
          onDelete={handleDeleteInstanceKey}
          onClose={onClose}
          isDeleting={isValidating}
        />
      </Box>
    </CardSinglePanel>
  );
}
