import React from 'react';
import { Center, Spinner, Text } from '@chakra-ui/react';
import { CardDualPanel, HelperLink } from '@components/common';
import { useLocation } from '@hooks';
import { useDashboardSWR } from '@hooks/useDashboardAPI';
import { InstanceKey, InstanceKeyObject } from './types';
import { useAnalytics } from '@hooks/useAnalytics';

import { APIKeyPanel } from './components';

export function PublishableKey(): JSX.Element {
  const { track } = useAnalytics();
  const { instanceId } = useLocation();

  const { data: instanceKeys } = useDashboardSWR<InstanceKey[]>(
    `/v1/instances/${instanceId}/instance_keys`,
  );

  const publishableKey = instanceKeys?.find(
    key => key.object === InstanceKeyObject.FapiKey,
  ) || {
    secret: '',
  };

  return (
    <CardDualPanel
      spacing={1}
      rightPanelStyles={{ overflow: 'hidden', flex: 1, ml: 0 }}
      title='Publishable key'
      subtitle={
        <>
          <Text as='span' textStyle='sm-normal' mt='2'>
            This key should be used in your frontend code, can be safely shared,
            and does not need to be kept secret.
          </Text>

          <HelperLink
            mb={0}
            display='block'
            mt='2'
            href='https://reference.clerk.dev/reference/frontend-api-reference/introduction'
            onClick={() => {
              track(
                'Dashboard_API Keys Screen_Publishable Key Learn More Link Clicked',
                {
                  surface: 'Dashboard',
                  location: 'API Keys Screen',
                  instanceId,
                },
              );
            }}
          >
            Learn more
          </HelperLink>
        </>
      }
      subTitleProps={{ mb: 0 }}
    >
      {!publishableKey?.secret ? (
        <Center maxWidth='358px' w='100%' h='100%' alignSelf='center'>
          <Spinner />
        </Center>
      ) : (
        <APIKeyPanel
          id='publishableKey'
          keyValue={publishableKey?.secret}
          title='Publishable key'
        />
      )}
    </CardDualPanel>
  );
}
