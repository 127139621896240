import React from 'react';

import { Flex, Text, Image, Box } from '@chakra-ui/react';

import SocialProviders from './SocialProviders';
import Identifiers from './Identifiers';
import BrowserNav from './BrowserNav';
import { motion } from 'framer-motion';

const SignInPreview = ({
  socialProviders,
  identifiers,
  applicationName,
}: {
  socialProviders: string[];
  identifiers: string[];
  applicationName: string;
}): JSX.Element => {
  return (
    <Flex
      flexDirection='column'
      alignItems='center'
      flex={1}
      justifyContent='center'
      position='relative'
      borderRadius='2xl'
      overflow='hidden'
      boxShadow='0px 24px 48px rgba(0, 0, 0, 0.16)'
      pt={2}
    >
      <BrowserNav applicationName={applicationName || 'My Application'} />
      <Box
        background='linear-gradient(225deg, #17CCFC 2.9%, #5D31FF 65.89%, #F35AFF 128.88%)'
        position='absolute'
        top={10}
        left={0}
        right={0}
        bottom={0}
      ></Box>
      <Flex
        as={motion.div}
        layout
        overflowY='auto'
        width='100%'
        pt={10}
        pb={2}
        flexDirection='column'
        alignItems='center'
        position='relative'
      >
        <Flex
          as={motion.div}
          layout
          borderRadius='2xl'
          pl={8}
          pr={8}
          pb={12}
          pt={9}
          flexDirection='column'
          width='25rem'
          background='white'
          position='relative'
        >
          <Flex
            layout
            layoutId='securedByClerk'
            as={motion.div}
            left='-30px'
            top='30px'
            position='absolute'
          >
            <Image
              transform='rotate180deg)'
              src='/assets/logos/secured_by_clerk.svg'
            />
          </Flex>
          <Flex as={motion.div} layout mb={10} gap={1} flexDirection='column'>
            <Text fontWeight='600' fontSize='1.25rem'>
              Sign in
            </Text>
            <Text color='blackAlpha.700'>
              to continue to {applicationName || 'My Application'}
            </Text>
          </Flex>
          <SocialProviders socialProviders={socialProviders} />
          <Identifiers
            showDivider={socialProviders.length > 0}
            identifiers={identifiers}
          />
          <Flex as={motion.div} layout gap={1} mt={8}>
            <Text color='blackAlpha.700' textStyle='sm-normal'>
              No account?{' '}
            </Text>
            <Text textStyle='sm-medium' color='primary.500'>
              Sign up
            </Text>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default SignInPreview;
