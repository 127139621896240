import {
  CardDualPanel,
  HelperLink,
  InformationBox,
  PageLoader,
  StickyBottomCTA,
} from '@components/common';
import React, { useEffect } from 'react';
import { preventSubmitOnEnter } from '@utils';
import { Box, Stack, VStack } from '@chakra-ui/react';
import { FormProvider, useForm } from 'react-hook-form';
import {
  AvatarSettings,
  useAvatarConfig,
  useInstance,
  useLocation,
} from '@hooks';
import { AvatarSettingsForm } from '@components/customization/avatars/AvatarSettingsForm';
import { AvatarPreview } from '@components/customization/avatars/AvatarPreview';

const FORM_ID = 'avatar_settings';

const AVATAR_SETTINGS_DEFAULTS: AvatarSettings = {
  ver: 1,
  user: {
    bgType: 'solid',
    bgColor: ['6C47FF'],
    fgType: 'initials',
    fgColor: 'FFFFFF',
  },
  org: {
    bgType: 'solid',
    bgColor: ['6C47FF'],
    fgType: 'initials',
    fgColor: 'FFFFFF',
  },
};

export const Avatars = (): JSX.Element => {
  const { instanceId, applicationId } = useLocation();
  const { instance } = useInstance();
  const { avatarConfig, upsertAvatarConfig } = useAvatarConfig(instanceId);
  const formMethods = useForm({
    defaultValues: AVATAR_SETTINGS_DEFAULTS,
    mode: 'onChange',
  });
  const { handleSubmit, formState, watch, reset } = formMethods;
  const { isDirty, isSubmitting } = formState;
  const values = watch();

  useEffect(() => {
    if (!!avatarConfig && Object.keys(avatarConfig).length > 0) {
      reset(avatarConfig);
    }
  }, [avatarConfig, reset]);

  const updateConfig = () => {
    const postBody = {
      ...values,
    };
    if (postBody.user.bgType === 'solid') {
      postBody.user.bgColor = postBody.user.bgColor.slice(0, 1);
    }
    if (postBody.org.bgType === 'solid') {
      postBody.org.bgColor = postBody.org.bgColor.slice(0, 1);
    }
    return upsertAvatarConfig({ arg: postBody });
  };

  if (!instanceId || !avatarConfig || !instance) {
    return <PageLoader />;
  }

  return (
    <FormProvider {...formMethods}>
      <VStack spacing={7} align='stretch' mb={7}>
        <form
          id={FORM_ID}
          onSubmit={handleSubmit(updateConfig)}
          onKeyDown={preventSubmitOnEnter}
        >
          <Stack spacing={8}>
            <CardDualPanel
              title='Default user avatar'
              subtitle='Design the default user avatar.'
              leftPanelChildren={<AvatarPreview type={'user'} />}
            >
              <AvatarSettingsForm
                type={'user'}
                subLabels={{
                  marble:
                    'Display a marble gradient in the background. The marble gradient is unique per user.',
                  solid: 'Display a solid color in the background.',
                  initials: 'Display the user’s initials in the foreground.',
                  silhouette:
                    'Display a generic user silhouette in the foreground.',
                }}
              />
            </CardDualPanel>
            <CardDualPanel
              title='Default organization logo'
              subtitle='Design the default organization avatar.'
              leftPanelChildren={
                <>
                  {instance?.organization_settings?.enabled ? (
                    <AvatarPreview type={'org'} />
                  ) : null}
                </>
              }
            >
              {instance?.organization_settings?.enabled ? (
                <AvatarSettingsForm
                  type={'org'}
                  subLabels={{
                    marble:
                      'Display a marble gradient in the background. The marble gradient is unique per organization.',
                    solid: 'Display a solid color in the background.',
                    initials:
                      'Display the organization’s initial in the foreground.',
                    silhouette:
                      'Display a generic organization silhouette in the foreground.',
                  }}
                />
              ) : (
                <InformationBox textStyle='sm-normal' color='gray.500' mb={8}>
                  <Box>
                    Organizations have not been enabled for this application.{' '}
                    <HelperLink
                      display='span'
                      href={`/apps/${applicationId}/instances/${instanceId}/organizations-settings`}
                    >
                      Go to Organization Settings
                    </HelperLink>
                  </Box>
                </InformationBox>
              )}
            </CardDualPanel>
          </Stack>
        </form>
        <StickyBottomCTA
          formId={FORM_ID}
          isVisible={isDirty}
          onReset={() => reset()}
          isSubmitting={isSubmitting}
        />
      </VStack>
    </FormProvider>
  );
};
