import React from 'react';
import {
  Center,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';
import { TableRow } from './TableRow';
import type { InstanceKey } from './types';

type KeysTableProps = {
  instanceKeys: InstanceKey[];
  onDelete: (instanceKeyId: string) => void;
  onShowKey: (instanceKeyId: string) => void;
  onHideKey: (instanceKeyId: string) => void;
  onCopyToken: (instanceKeyId: string) => void;
};

export function KeysTable({
  instanceKeys,
  onDelete,
  onShowKey,
  onHideKey,
  onCopyToken,
}: KeysTableProps): JSX.Element {
  return (
    <>
      <Table>
        <Thead>
          <Tr>
            <Th width='200px'>Name</Th>
            <Th width='400px'>Token</Th>
            <Th>Created at</Th>
            <Th />
          </Tr>
        </Thead>
        <Tbody>
          {instanceKeys.map(instanceKey => (
            <TableRow
              key={instanceKey.id}
              {...instanceKey}
              onDelete={onDelete}
              onShowKey={onShowKey}
              onHideKey={onHideKey}
              onCopyToken={onCopyToken}
            />
          ))}
          {!instanceKeys.length ? <EmptyTableRow /> : null}
        </Tbody>
      </Table>
    </>
  );
}

const EmptyTableRow = () => (
  <Tr>
    <Td colSpan={3}>
      <Center>
        <Text textStyle='sm-normal'>No keys to display</Text>
      </Center>
    </Td>
  </Tr>
);
