import { Controller, useFieldArray, useFormContext } from 'react-hook-form';
import { Button, Flex, FormControl, Stack } from '@chakra-ui/react';
import debounce from 'lodash/debounce';
import { ColorInput } from '@components/common';
import React from 'react';

type MultipleColorFieldsProps = {
  name: string;
  maxFields?: number;
  defaultColor?: string;
};

export const MultipleColorFields = ({
  name,
  maxFields = 3,
  defaultColor = 'FFFFFF',
}: MultipleColorFieldsProps): JSX.Element => {
  const { control, formState } = useFormContext();
  const { errors } = formState;
  const { fields, append, remove } = useFieldArray({ control, name });

  return (
    <Stack spacing={2} shouldWrapChildren>
      {fields.slice(0, maxFields).map((field, index) => (
        <div key={field.id}>
          <Flex alignItems={'center'}>
            <FormControl isInvalid={!!errors?.name} width={'195px'}>
              <Controller
                control={control}
                name={`${name}.${index}`}
                render={({ field: { onChange, value, name } }) => {
                  const handleChange = debounce(e => {
                    onChange(e.slice(1));
                  }, 300);
                  return (
                    <ColorInput
                      name={name}
                      value={`#${value}`}
                      onChange={handleChange}
                    />
                  );
                }}
              />
            </FormControl>
            {index !== 0 && (
              <Button
                ml={4}
                mt={0}
                onClick={() => remove(index)}
                variant='link'
                textTransform='none'
                color={'danger.500'}
              >
                Remove
              </Button>
            )}
          </Flex>
        </div>
      ))}
      {fields.length < maxFields && (
        <Button
          onClick={() => append(defaultColor)}
          variant='link'
          textTransform='none'
        >
          + Add color
        </Button>
      )}
    </Stack>
  );
};
