import React from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import {
  useDashboardCRUD,
  useDashboardSWR,
  useInstance,
  useLocation,
  useToast,
} from '@hooks';
import { HTTPError } from '@utils';
import { CardDualPanel, PageLoader, StickyBottomCTA } from '..';
import { PATH_OPTIONS } from './constants';
import { PathSetting } from './PathSetting';
import {
  filterOptionsThatRequireOrganizations,
  getSingleSessionFieldsOnly,
  isSingleSessionMode,
} from './utils';

const FORM_ID = 'path_options_settings';

export function PathOptions(): JSX.Element {
  const { instanceId } = useLocation();
  const {
    data: displayConfig,
    isValidating: isValidatingDisplayConfig,
    mutate: mutateDisplayConfig,
  } = useDashboardSWR(() => `/v1/instances/${instanceId}/display_config`);
  const { data: userSettings, isValidating: isValidatingUserSettings } =
    useDashboardSWR(`/v1/instances/${instanceId}/user_settings`);
  const { update } = useDashboardCRUD();
  const { showSuccessToast } = useToast();
  const { instance } = useInstance();
  const organizationsEnabled = !!instance?.organization_settings.enabled;

  const formMethods = useForm();
  const { handleSubmit, formState, reset, setError, clearErrors } = formMethods;
  const { isDirty, isSubmitting } = formState;
  const [updateKey, setUpdateKey] = React.useState(new Date());

  React.useEffect(() => {
    if (displayConfig) {
      reset(displayConfig);
    }
  }, [displayConfig, reset]);

  const onSubmitPaths = async paths => {
    try {
      clearErrors();
      await update(`/v1/instances/${instanceId}/display_config`, {
        ...displayConfig,
        ...paths,
      });
      await mutateDisplayConfig();
      reset(paths);
      showSuccessToast('Path settings saved.');
    } catch (err) {
      // TODO: Add validation in RHF using the same algorithm as in the backend
      if (err?.name === 'HTTPError') {
        (err as HTTPError)?.fieldErrors.forEach(err => {
          const param = err?.meta?.param_name;
          if (param) {
            setError(param, { type: 'manual' });
          }
        });
      } else {
        return;
      }
    }
  };

  const availablePathOptions = React.useMemo(() => {
    const pathOptions = PATH_OPTIONS.filter(
      filterOptionsThatRequireOrganizations(organizationsEnabled),
    );
    return isSingleSessionMode(userSettings)
      ? pathOptions.map(getSingleSessionFieldsOnly)
      : pathOptions;
  }, [userSettings]);

  if (isValidatingDisplayConfig || isValidatingUserSettings || !displayConfig) {
    return <PageLoader />;
  }

  return (
    <FormProvider {...formMethods}>
      {availablePathOptions.map(pathOption => (
        <CardDualPanel key={pathOption.title} mb={8} {...pathOption}>
          <form id={FORM_ID} onSubmit={handleSubmit(onSubmitPaths)}>
            {pathOption.fields.map((field, fieldIdx) => (
              <PathSetting
                key={updateKey + field.title}
                defaultUrl={displayConfig[field.urlKey]}
                customPath={displayConfig[field.customPathKey]}
                isLast={fieldIdx === pathOption.fields.length - 1}
                {...field}
              />
            ))}
            <StickyBottomCTA
              formId={FORM_ID}
              isVisible={isDirty}
              onReset={() => {
                reset(displayConfig);
                setUpdateKey(new Date());
              }}
              isSubmitting={isSubmitting}
            />
          </form>
        </CardDualPanel>
      ))}
    </FormProvider>
  );
}
