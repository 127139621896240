export const PATH_OPTIONS = Object.freeze([
  {
    title: 'Home',
    subtitle: 'Indicate your application’s domain to set path prefixes',
    hint: "Leave blank to use the Clerk Hosted Pages or enter your application's home path",
    fields: [
      {
        title: 'Home URL',
        subtitle: 'Enter the path that displays your application’s home page',
        inputPlaceholder: '/my-dashboard',
        /* Key corresponding to the instance display_config */
        customPathKey: 'home_path',
        urlKey: 'default_home_url',
      },
    ],
  },
  {
    title: 'Sign-up',
    subtitle: 'Configure redirection paths for the sign-up flow',
    hint: 'Leave blank to use Clerk Hosted Pages URL, or enter a slug to use a custom URL',
    fields: [
      {
        title: 'Sign-up URL',
        subtitle: 'Set where to display the sign-up flow',
        inputPlaceholder: '/sign-up',
        customPathKey: 'sign_up_path',
        urlKey: 'default_sign_up_url',
      },
      {
        title: 'Sign-up redirect',
        subtitle: 'Set where to send the user after signing up',
        inputPlaceholder: '/default-redirect',
        customPathKey: 'after_sign_up_path',
        urlKey: 'default_after_sign_up_url',
      },
    ],
  },
  {
    title: 'Sign-in',
    subtitle: 'Configure redirection paths for the sign-in flow',
    hint: 'Leave blank to use Clerk Hosted Pages URL, or enter a slug to use a custom URL',
    fields: [
      {
        title: 'Sign-in URL',
        subtitle: 'Set where to display the sign-in flow',
        inputPlaceholder: '/sign-in',
        customPathKey: 'sign_in_path',
        urlKey: 'default_sign_in_url',
      },
      {
        title: 'Sign-in redirect',
        subtitle: 'Set where to send the user after signing in',
        inputPlaceholder: '/default-redirect',
        customPathKey: 'after_sign_in_path',
        urlKey: 'default_after_sign_in_url',
      },
      {
        title: 'Session-switch redirect',
        subtitle: 'Set where to send the user after switching accounts',
        inputPlaceholder: '/default-redirect',
        customPathKey: 'after_switch_session_path',
        urlKey: 'default_after_switch_session_url',
        availablelInMultiSession: true,
      },
    ],
  },
  {
    title: 'User profile',
    subtitle: 'Configure redirection paths for the user profile pages',
    hint: 'Leave blank to use Clerk Hosted Pages URL, or enter a slug to use a custom URL',
    fields: [
      {
        title: 'User profile URL',
        subtitle: 'Set where to display the user profile page',
        inputPlaceholder: '/user',
        customPathKey: 'user_profile_path',
        urlKey: 'default_user_profile_url',
      },
    ],
  },
  {
    title: 'Organization profile',
    subtitle: 'Configure redirection paths for the organization profile pages',
    hint: 'Leave blank to use Clerk Hosted Pages URL, or enter a slug to use a custom URL',
    requiresOrganizations: true,
    fields: [
      {
        title: 'Organization profile URL',
        subtitle: 'Set where to display the organization profile page',
        inputPlaceholder: '/organization',
        customPathKey: 'organization_profile_path',
        urlKey: 'default_organization_profile_url',
      },
      {
        title: 'Leave organization redirect',
        subtitle: 'Set where to send the user after they leave an organization',
        inputPlaceholder: '/default-redirect',
        customPathKey: 'after_leave_organization_path',
        urlKey: 'default_after_leave_organization_url',
      },
    ],
  },
  {
    title: 'Create organization',
    subtitle: 'Configure redirection paths for the create organization page',
    hint: 'Leave blank to use Clerk Hosted Pages URL, or enter a slug to use a custom URL',
    requiresOrganizations: true,
    fields: [
      {
        title: 'Create organization URL',
        subtitle: 'Set where to display the create organization page',
        inputPlaceholder: '/create-organization',
        customPathKey: 'create_organization_path',
        urlKey: 'default_create_organization_url',
      },
      {
        title: 'Create organization redirect',
        subtitle: 'Set where to send the user after an organization is created',
        inputPlaceholder: '/default-redirect',
        customPathKey: 'after_create_organization_path',
        urlKey: 'default_after_create_organization_url',
      },
    ],
  },
  {
    title: 'Sign-out',
    subtitle: 'Configure redirection paths for the sign-in flow',
    hint: 'Leave blank to use Clerk Hosted Pages URL, or enter a slug to use a custom URL',
    fields: [
      {
        title: 'Sign-out redirect, single account',
        subtitle:
          'Set where to send the user after signing out of a single account when signed into multiple',
        inputPlaceholder: '/sign-in/choose',
        customPathKey: 'after_sign_out_one_path',
        urlKey: 'default_after_sign_out_one_url',
        availablelInMultiSession: true,
      },
      {
        title: 'Sign-out redirect, all accounts',
        subtitle:
          'Set where to send the user after signing out of all accounts',
        inputPlaceholder: '/sign-in',
        customPathKey: 'after_sign_out_all_path',
        urlKey: 'default_after_sign_out_all_url',
      },
    ],
  },
]);
