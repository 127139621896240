import React from 'react';
import { Flex, Text, Image } from '@chakra-ui/react';
import { svgUrl } from '@utils';
import _capitalize from 'lodash/capitalize';
import { motion } from 'framer-motion';

export const SocialButtonBlock = ({
  provider,
}: {
  provider: string;
}): JSX.Element => {
  return (
    <Flex
      layoutId={provider}
      layout
      key={provider}
      as={motion.div}
      border='1px solid'
      borderColor='blackAlpha.200'
      width='100%'
      gap={4}
      py={2.5}
      px={4}
      flexDirection='row'
      alignItems='center'
      borderRadius='md'
      initial={{ opacity: 0 }}
      animate={{ opacity: 1, transition: { duration: 0.3, type: 'spring' } }}
      exit={{
        border: 0,
        gap: 0,
        height: 0,
        padding: 0,
        opacity: 0,
        transition: { duration: 0.3, type: 'spring' },
        margin: 0,
      }}
      mb={2}
    >
      <Image src={svgUrl(provider)} boxSize={4} />
      <Text textStyle='sm-normal'>Continue with {_capitalize(provider)}</Text>
    </Flex>
  );
};

export const SocialButtonIcon = ({
  provider,
}: {
  provider: string;
}): JSX.Element => {
  return (
    <Flex
      layoutId={provider}
      layout
      key={provider}
      as={motion.div}
      border='1px solid'
      borderColor='blackAlpha.200'
      width='100%'
      gap={4}
      py={4}
      px={2.5}
      flexDirection='row'
      alignItems='center'
      borderRadius='md'
      justifyContent='center'
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{
        gap: 0,
        height: 0,
        padding: 0,
        opacity: 0,
        transition: { duration: 0.3 },
      }}
    >
      <Image src={svgUrl(provider)} boxSize={4} />
    </Flex>
  );
};
