import React from 'react';
import {
  Input,
  Flex,
  Text,
  Box,
  Icon,
  Button,
  InputGroup,
  InputLeftAddon,
} from '@chakra-ui/react';
import _capitalize from 'lodash/capitalize';
import { motion, AnimatePresence } from 'framer-motion';
import { ChevronDownIcon } from '@heroicons/react/solid';

const Identifiers = ({
  identifiers,
  showDivider,
}: {
  identifiers: string[];
  showDivider: boolean;
}): JSX.Element => {
  return (
    <Flex gap={8} as={motion.div} layout flexDirection='column'>
      <AnimatePresence>
        {identifiers.length > 0 && (
          <Flex
            flexDirection='column'
            key='identifiers'
            layoutId='identifiers'
            as={motion.div}
            layout
            exit={{ height: 0, opacity: 0, transition: { duration: 0.3 } }}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
          >
            <AnimatePresence>
              {showDivider && (
                <Flex
                  layoutId='divider'
                  layout
                  exit={{
                    height: 0,
                    opacity: 0,
                    transition: { duration: 0.3 },
                    gap: 0,
                    margin: 0,
                  }}
                  as={motion.div}
                  alignItems='center'
                  mt={8}
                  mb={8}
                  gap={4}
                  key='divider'
                >
                  <Box height='1px' width='100%' bg='rgba(0, 0, 0, 0.16)' />
                  <Text textStyle='sm-medium'>or</Text>
                  <Box bg='rgba(0, 0, 0, 0.16)' height='1px' width='100%' />
                </Flex>
              )}
            </AnimatePresence>
            <Flex as={motion.div} layout direction='column'>
              <AnimatePresence>
                {identifiers?.map(identifier => (
                  <Flex
                    layoutId={identifier}
                    mb={4}
                    layout
                    initial={{ opacity: 0 }}
                    animate={{
                      opacity: 1,
                    }}
                    exit={{
                      margin: 0,
                      height: 0,
                      opacity: 0,
                    }}
                    as={motion.div}
                    key={identifier}
                    direction='column'
                  >
                    <Text
                      color='hsla(0, 0%, 8%, 1)'
                      textStyle='sm-medium'
                      mb={1}
                    >
                      {_capitalize(identifier.replace(/_/g, ' '))}
                    </Text>
                    {identifier === 'phone_number' ? (
                      <InputGroup>
                        <InputLeftAddon
                          bg='rgba(0, 0, 0, 0.04)'
                          paddingRight={2}
                        >
                          <Text color='gray.600' fontSize='xs'>
                            🇺🇸 +1
                          </Text>
                          <Icon
                            as={ChevronDownIcon}
                            boxSize={4}
                            color='gray.600'
                            ml={1}
                          />
                        </InputLeftAddon>
                        <Input
                          borderLeftWidth={0}
                          disabled
                          _disabled={{ borderColor: 'blackAlpha.300' }}
                          borderColor='blackAlpha.300'
                          type='number'
                          autoComplete='off'
                          autoCapitalize='off'
                        />
                      </InputGroup>
                    ) : (
                      <Input
                        disabled
                        _disabled={{ borderColor: 'blackAlpha.300' }}
                        borderColor='blackAlpha.300'
                        name={identifier}
                      />
                    )}
                  </Flex>
                ))}
              </AnimatePresence>

              <Button
                cursor='initial'
                disabled
                _disabled={{ bg: 'primary.500' }}
              >
                Continue
              </Button>
            </Flex>
          </Flex>
        )}
      </AnimatePresence>
    </Flex>
  );
};

export default Identifiers;
