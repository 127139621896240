export const SUPPORTED_FEATURES = {
  allowlist: 'allowlist',
  blocklist: 'blocklist',
  custom_email_template: 'custom_email_template',
  custom_sms_template: 'custom_sms_template',
  custom_session_duration: 'custom_session_duration',
  default_session_duration: 'default_session_duration',
  custom_session_inactivity_timeout: 'custom_session_inactivity_timeout',
  custom_session_token: 'custom_session_token',
  max_3_social_and_web3: 'max_3_social_and_web3',
  mfa_backup_code: 'mfa_backup_code',
  mfa_phone_code: 'mfa_phone_code',
  mfa_totp: 'mfa_totp',
  multi_session: 'multi_session',
  phone_code: 'phone_code',
  remove_branding: 'remove_branding',
  unlimited_social: 'unlimited_social',
  magic_link: 'magic_link',
  email_code: 'email_code',
  password: 'password',
  unlimited_integration: 'unlimited_integration',
  custom_jwt_template: 'custom_jwt_template',
  webhooks: 'webhooks',
  unlimited_web3: 'unlimited_web3',
  impersonation: 'impersonation',
  ban_user: 'ban_user',
  unlimited_org_memberships: 'unlimited_org_memberships',
  allowed_memberships: 'allowed_memberships',
};

export const SUPPORTED_FEATURES_TITLES = {
  [SUPPORTED_FEATURES.allowlist]: 'Allowlist',
  [SUPPORTED_FEATURES.blocklist]: 'Blocklist',
  [SUPPORTED_FEATURES.custom_email_template]: 'Custom email templates',
  [SUPPORTED_FEATURES.custom_sms_template]: 'Custom SMS templates',
  [SUPPORTED_FEATURES.custom_session_duration]: 'Custom session duration',
  [SUPPORTED_FEATURES.custom_session_inactivity_timeout]: 'Inactivity timeout',
  [SUPPORTED_FEATURES.custom_session_token]: 'Custom session token',
  [SUPPORTED_FEATURES.mfa_backup_code]:
    'Multi factor authentication via backup code',
  [SUPPORTED_FEATURES.mfa_phone_code]:
    'Multi factor authentication via phone code',
  [SUPPORTED_FEATURES.mfa_totp]: 'Multi factor authentication via TOTP',
  [SUPPORTED_FEATURES.multi_session]: 'Multi session',
  [SUPPORTED_FEATURES.phone_code]: 'Phone numbers',
  [SUPPORTED_FEATURES.remove_branding]: 'Hide Clerk branding',
  [SUPPORTED_FEATURES.unlimited_social]: 'Unlimited social connections',
  [SUPPORTED_FEATURES.unlimited_web3]: 'Unlimited web3 logins',
  [SUPPORTED_FEATURES.magic_link]: 'Email magic links',
  [SUPPORTED_FEATURES.email_code]: 'Email OTPs',
  [SUPPORTED_FEATURES.password]: 'Passwords',
  [SUPPORTED_FEATURES.unlimited_integration]: 'Unlimited integrations',
  [SUPPORTED_FEATURES.custom_jwt_template]: 'Custom JWT templates',
  [SUPPORTED_FEATURES.webhooks]: 'Webhooks',
  [SUPPORTED_FEATURES.impersonation]: 'User impersonation',
  [SUPPORTED_FEATURES.ban_user]: 'Ban user',
  [SUPPORTED_FEATURES.allowed_memberships]:
    'Unlimited organization memberships',
};
