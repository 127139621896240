import useSWR from 'swr';
import { useAuth } from '@clerk/nextjs';

const IMG_SERVICE_URL = process.env.NEXT_PUBLIC_IMG_SERVICE_URL;

export type AvatarConfigBgType = 'marble' | 'solid';
export type AvatarConfigFgType = 'initials' | 'silhouette' | 'none';

export type AvatarConfig = {
  bgType: AvatarConfigBgType;
  bgColor: string[];
  fgType: AvatarConfigFgType;
  fgColor: string;
};

export type AvatarSettings = {
  ver: number;
  user: AvatarConfig;
  org: AvatarConfig;
};

export const useAvatarConfig = (instanceId: string) => {
  const { getToken } = useAuth();

  const fetcher = async (url: string): Promise<AvatarSettings> => {
    return getToken()
      .then(token =>
        fetch(url, { headers: { Authorization: `Bearer ${token}` } }),
      )
      .then(res => res.json());
  };

  const key = `${IMG_SERVICE_URL}/api/instances/${instanceId}`;
  const { data, mutate, ...rest } = useSWR(key, fetcher);

  const upsertAvatarConfig = ({ arg }: { arg: any }) => {
    return getToken()
      .then(token =>
        fetch(key, {
          method: 'POST',
          body: JSON.stringify(arg),
          headers: { Authorization: `Bearer ${token}` },
        }),
      )
      .then(() => mutate());
  };

  return {
    avatarConfig: data,
    upsertAvatarConfig,
    ...rest,
  };
};
