import React from 'react';
import { Flex, Link } from '@chakra-ui/react';
import { useLocation, usePlausible } from '@hooks';
import { CardSinglePanel } from '@components/common';
import { STARTERS } from './constants';
import { Framework } from './Framework';
import { useAnalytics } from '@hooks/useAnalytics';

export function StartBuilding(): JSX.Element {
  const { track } = useAnalytics();
  const plausible = usePlausible();
  const { instanceId } = useLocation();

  return (
    <CardSinglePanel
      title='Quickstarts'
      subtitle='Choose your framework below. The average installation takes only 16 minutes.'
      subtitleStyles={{ mb: 6 }}
    >
      <Flex gridGap={2}>
        {STARTERS.map(({ title, guide_url, logo }) => (
          <Link
            key={title}
            href={guide_url}
            isExternal
            maxWidth='144px'
            w='full'
            _focus={{}}
            onClick={() => {
              plausible('Read guide', {
                props: {
                  Framework: title as any,
                },
              });
              track(
                'Dashboard_Instance Overview_Start Building Card Link Clicked',
                {
                  surface: 'Dashboard',
                  location: 'Instance Overview',
                  instanceId,
                  clickedOn: title,
                },
              );
            }}
          >
            <Framework title={title} logo={logo} />
          </Link>
        ))}
      </Flex>
    </CardSinglePanel>
  );
}
