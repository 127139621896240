import React from 'react';
import { Button, Flex, Text, VStack } from '@chakra-ui/react';
import { useSupportedFeature } from '@hooks';
import { Modal } from '@components/common';
import { Checkbox } from './Checkbox';
import { noop } from '@utils';
import { SwitchField } from './SwitchField';
import { ExclamationIcon, InformationCircleIcon } from '@heroicons/react/solid';
import { SUPPORTED_FEATURES } from '@constants';

type PhoneNumberConfigModalProps = {
  isOpen: boolean;
  onClose: () => void;
  isProgressiveSignUp: boolean;
};

export function PhoneNumberConfigModal({
  isOpen,
  onClose,
  isProgressiveSignUp,
}: PhoneNumberConfigModalProps): JSX.Element {
  const { isSupported } = useSupportedFeature();
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      autoFocus={false}
      size='xl'
      title='Phone number'
      subtitle='Configure phone number attribute'
    >
      <Modal.Body>
        <VStack spacing='6'>
          {isProgressiveSignUp && (
            <SwitchField
              name='attributes.phone_number.required'
              label='Require'
              description='Users must provide a phone number to sign up, and must maintain one on their account at all times'
              info={[
                {
                  Icon: InformationCircleIcon,
                  text: 'Enable this setting if you need to contact users by phone (e.g. for SMS notifications).',
                },
              ]}
            />
          )}
          <SwitchField
            name='attributes.phone_number.used_for_first_factor'
            label='Sign-in'
            description='Allow users to sign in with their phone number'
          />
          {isProgressiveSignUp && (
            <SwitchField
              name='attributes.phone_number.verify_at_sign_up'
              label='Verify at sign-up'
              description='Require users to verify their phone numbers before they can sign up'
              info={[
                {
                  Icon: ExclamationIcon,
                  text: 'Not verifying phone numbers may result in low-quality phone numbers in your user base',
                },
              ]}
            />
          )}
          <VStack spacing='1' w='full'>
            <Text textStyle='lg-medium' w='full'>
              Verification methods
            </Text>
            <Text color='gray.500' textStyle='md-normal' w='full'>
              Select how users can verify a phone number
            </Text>

            <VStack align='flex-start' w='full' p='4' spacing='4'>
              <Checkbox
                isChecked
                showTag={!isSupported(SUPPORTED_FEATURES.phone_code)}
                isDisabled
                onChange={noop}
                name='attributes.phone_number.verifications'
                label='SMS verification code'
                description='Verify by entering an one-time verification code sent via SMS to the phone number'
              />
            </VStack>
          </VStack>
        </VStack>
      </Modal.Body>

      <Modal.Footer mt='6'>
        <Flex direction='row' w='full'>
          <Flex justify='space-between' w='full' flexDir='row-reverse'>
            <Button onClick={onClose}>Continue</Button>
          </Flex>
        </Flex>
      </Modal.Footer>
    </Modal>
  );
}
