import { Radio, RadioGroup, Stack } from '@chakra-ui/react';
import { VerticalInputBox } from '@components/common';
import { MultipleColorFields } from '@components/customization/avatars/MultipleColorFields';
import React from 'react';
import { useController, useFormContext } from 'react-hook-form';

type AvatarBackgroundRadioGroupSubLabels = {
  marble: string;
  solid: string;
};

type AvatarBackgroundRadioGroupProps = {
  type: 'org' | 'user';
  subLabels: AvatarBackgroundRadioGroupSubLabels;
};

export const AvatarBackgroundRadioGroup = ({
  type,
  subLabels,
}: AvatarBackgroundRadioGroupProps): JSX.Element => {
  const { watch, control } = useFormContext();
  const { field } = useController({ name: `${type}.bgType`, control });
  const values = watch();
  return (
    <RadioGroup
      onChange={nextValue => field.onChange(nextValue)}
      value={field.value}
    >
      <Stack direction='column' spacing={4}>
        <Radio value='marble' alignItems={'flex-start'}>
          <VerticalInputBox label='Marble' subLabel={subLabels.marble}>
            {values[type].bgType === 'marble' && (
              <MultipleColorFields name={`${type}.bgColor`} />
            )}
          </VerticalInputBox>
        </Radio>
        <Radio value='solid' alignItems={'flex-start'}>
          <VerticalInputBox label='Solid' subLabel={subLabels.solid}>
            {values[type].bgType === 'solid' && (
              <MultipleColorFields name={`${type}.bgColor`} maxFields={1} />
            )}
          </VerticalInputBox>
        </Radio>
      </Stack>
    </RadioGroup>
  );
};
