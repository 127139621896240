import { Stack, Text } from '@chakra-ui/react';
import React from 'react';
import { AvatarBackgroundRadioGroup } from '@components/customization/avatars/AvatarBackgroundRadioGroup';
import { AvatarForegroundRadioGroup } from '@components/customization/avatars/AvatarForegroundRadioGroup';

type SubLabelTexts = {
  marble: string;
  solid: string;
  initials: string;
  silhouette: string;
};

type AvatarType = 'user' | 'org';

type AvatarSettingsFormProps = {
  type: AvatarType;
  subLabels: SubLabelTexts;
};

export const AvatarSettingsForm = ({
  type,
  subLabels,
}: AvatarSettingsFormProps): JSX.Element => {
  const { marble, solid, initials, silhouette } = subLabels;
  return (
    <Stack spacing={4}>
      <Text fontWeight={500}>Background</Text>
      <AvatarBackgroundRadioGroup type={type} subLabels={{ marble, solid }} />
      <Text fontWeight={500}>Foreground</Text>
      <AvatarForegroundRadioGroup
        type={type}
        subLabels={{ initials, silhouette }}
      />
    </Stack>
  );
};
