import React from 'react';
import { Center, Spinner, Text } from '@chakra-ui/react';
import { CardDualPanel, CopyTextArea } from '@components/common';
import { useLocation } from '@hooks';
import { useDashboardSWR } from '@hooks/useDashboardAPI';
import { type InstanceKey, InstanceKeyObject } from './types';

export function JwtVerificationKey(): JSX.Element {
  const { instanceId } = useLocation();

  const { data: instanceKeys } = useDashboardSWR<InstanceKey[]>(
    `/v1/instances/${instanceId}/instance_keys`,
  );

  const publicKey = instanceKeys?.find(
    key => key.object === InstanceKeyObject.PublicKey,
  ) || {
    secret: '',
  };

  return (
    <CardDualPanel
      title='Legacy JWT verification key'
      subtitle={
        <>
          <Text as='span' textStyle='sm-normal' mt='2'>
            This is your instance's public key. It can be optionally used to
            verify the session tokens that your application receives.
          </Text>
        </>
      }
      rightPanelStyles={{ flex: '2', alignSelf: 'stretch' }}
      direction={['column', null, null, 'column', 'row']}
      bgColor='gray.50'
    >
      {!publicKey?.secret ? (
        <Center maxWidth='358px' w='100%' h='100%' alignSelf='center'>
          <Spinner />
        </Center>
      ) : (
        <CopyTextArea
          label='Key'
          valueToCopy={publicKey?.secret}
          textareaProps={{
            resize: 'none',
            rows: 7,
            fontFamily: 'sourceCodePro',
          }}
          paddingTop={0}
        />
      )}
    </CardDualPanel>
  );
}
