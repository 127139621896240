import React from 'react';
import {
  Button,
  Collapse,
  Icon,
  Text,
  useDisclosure,
  Flex,
} from '@chakra-ui/react';
import { CardDualPanel, HelperLink } from '@components/common';

import { useFrontendAPI, useLocation } from '@hooks';
import { useDashboardSWR } from '@hooks/useDashboardAPI';
import type { InstanceKey } from './types';
import { InstanceKeyObject } from './types';
import { ChevronDownIcon, ChevronRightIcon } from '@heroicons/react/solid';
import { useAnalytics } from '@hooks/useAnalytics';

import { APIKeyTabbedPanel, APIKeyPanelProps, APIKeyPanel } from './components';

export function PublicKey(): JSX.Element {
  const { track } = useAnalytics();
  const { instanceId } = useLocation();
  const frontendAPI = useFrontendAPI();

  const { isOpen, onOpen, onClose } = useDisclosure();

  const jwksUrl = `https://${frontendAPI}/.well-known/jwks.json`;

  const { data: instanceKeys } = useDashboardSWR<InstanceKey[]>(
    `/v1/instances/${instanceId}/instance_keys`,
  );

  const clerkFrontEndApiPanel: APIKeyPanelProps = {
    keyValue: frontendAPI,
    title: 'Frontend API URL',
    id: 'front_end_api_url',
  };

  const publicKeyPEM = instanceKeys?.find(
    key => key.object === InstanceKeyObject.PublicKeyPEM,
  ) || {
    secret: '',
  };
  const makeAPIViewKeys = (): APIKeyPanelProps[] => {
    if (!publicKeyPEM?.secret) {
      return [
        {
          title: 'JWKS URL',
          id: 'JWKS_URL',
          keyValue: jwksUrl,
        },
        {
          title: 'PEM public key',
          id: 'public_PEM',
          isLoading: true,
          keyValue: '',
          description: 'Use the PEM format directly in your code.',
        },
      ];
    }

    return [
      {
        title: 'JWKS URL',
        id: 'JWKS_URL',
        keyValue: jwksUrl,
        description:
          'Use the JSON Web Key Set (JWKS) URL to lookup the public key.',
      },
      {
        title: 'PEM public key',
        id: 'public_PEM',
        keyValue: publicKeyPEM?.secret,
        description: 'Use the PEM format directly in your code.',
      },
    ];
  };

  return (
    <>
      {isOpen ? (
        <Button
          variant='link'
          textTransform='none'
          alignSelf='baseline'
          onClick={onClose}
        >
          Advanced
          <Icon as={ChevronDownIcon} boxSize={5} ml={1} />
        </Button>
      ) : (
        <Button
          variant='link'
          textTransform='none'
          alignSelf='baseline'
          onClick={() => {
            onOpen();
            track('Dashboard_API Keys Screen_Advanced Accordion Clicked', {
              surface: 'Dashboard',
              location: 'API Keys Screen',
              instanceId,
            });
          }}
        >
          Advanced
          <Icon as={ChevronRightIcon} boxSize={5} ml={1} />
        </Button>
      )}

      <Collapse in={isOpen}>
        <Flex flexDirection='column' gap={8}>
          <CardDualPanel
            spacing={1}
            title='Clerk API URLs'
            subtitle={
              <>
                <Text as='span' textStyle='sm-normal' mt='2'>
                  These may be requested by some services.
                </Text>
                <HelperLink
                  display='block'
                  mt='2'
                  href='https://clerk.com/docs/reference/overview'
                  onClick={() => {
                    track(
                      'Dashboard_API Keys Screen_JWT Public Key Learn More Link Clicked',
                      {
                        surface: 'Dashboard',
                        location: 'API Keys Screen',
                        instanceId,
                      },
                    );
                  }}
                >
                  Learn more
                </HelperLink>
              </>
            }
            rightPanelStyles={{ flex: '1', overflow: 'hidden' }}
            direction={['column', null, null, 'column', 'row']}
          >
            <APIKeyPanel {...clerkFrontEndApiPanel} />
          </CardDualPanel>
          <CardDualPanel
            spacing={1}
            title='JWT public key'
            subtitle={
              <>
                <Text as='span' textStyle='sm-normal' mt='2'>
                  This key is used to verify Clerk generated JWTs.
                </Text>
                <HelperLink
                  display='block'
                  mt='2'
                  href='https://clerk.com/docs/request-authentication/validate-session-tokens'
                  onClick={() => {
                    track(
                      'Dashboard_API Keys Screen_JWT Public Key Learn More Link Clicked',
                      {
                        surface: 'Dashboard',
                        location: 'API Keys Screen',
                        instanceId,
                      },
                    );
                  }}
                >
                  Learn more
                </HelperLink>
              </>
            }
            rightPanelStyles={{ flex: '1', overflow: 'hidden' }}
            direction={['column', null, null, 'column', 'row']}
          >
            <APIKeyTabbedPanel apiKeys={makeAPIViewKeys()} />
          </CardDualPanel>
        </Flex>
      </Collapse>
    </>
  );
}
